<template>
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">Messages</span>
      </h3>
      <div class="card-toolbar">
        <router-link
          :to="{ name: 'message/create' }"
          class="btn btn-info font-weight-bolder font-size-sm mr-3"
        >
          New Message
        </router-link>
        <button
          type="button"
          @click="$router.back()"
          class="btn btn-info font-weight-bolder font-size-sm mr-3"
        >
          Back
        </button>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-0 pb-3">
      <div class="tab-content">
        <!--begin::Table-->
        <div class="table-responsive">
          <table
            class="table table-head-custom table-vertical-center table-head-bg table-borderless"
          >
            <thead>
              <tr class="text-left">
                <th style="min-width: 120px" class="pl-7">
                  <span class="text-dark-75">Message Type</span>
                </th>
                <th style="min-width: 120px">Language</th>
                <th style="min-width: 100px">Digit</th>
                <th style="min-width: 250px">Message</th>
                <th style="min-width: 100px">Children</th>
                <th style="min-width: 100px"></th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(item, i) in list">
                <tr v-bind:key="i">
                  <td class="pl-0 py-8">
                    <span
                      class="text-dark-75 font-weight-bolder d-block font-size-lg"
                      >{{ item.messageType }}</span
                    >
                  </td>
                  <td>
                    <span
                      class="text-dark-75 font-weight-bolder d-block font-size-lg"
                      >{{
                        item.messageType == "say"
                          ? item.sayLanguage
                          : item.language
                      }}</span
                    >
                  </td>
                  <td>
                    <span
                      class="text-dark-75 font-weight-bolder d-block font-size-lg"
                      >{{ item.digit }}</span
                    >
                  </td>
                  <td>
                    <span
                      v-if="item.messageType == 'say'"
                      class="text-dark-75 font-weight-bolder d-block font-size-lg"
                      >{{ item.message }}</span
                    >
                    <audio controls v-if="item.messageType == 'play'">
                      <source :src="item.fullUrl" type="audio/mpeg" />
                      Your browser does not support the audio tag.
                    </audio>
                  </td>
                  <td>
                    <router-link
                      :to="{
                        name: 'message/children',
                        params: { id: item.id }
                      }"
                      class="btn btn-info font-weight-bolder font-size-sm"
                      v-slot="{ navigate }"
                    >
                      <button
                        @click="navigate"
                        :disabled="item.childrenCount == 0"
                      >
                        View {{ item.childrenCount }} children
                      </button>
                    </router-link>
                  </td>
                  <td class="pr-0 text-right">
                    <router-link
                      :to="{ name: 'message/edit', params: { id: item.id } }"
                      class="btn btn-light-success font-weight-bolder font-size-sm"
                      >Edit</router-link
                    >
                    <button
                      class="btn btn-light-danger font-weight-bolder font-size-sm ml-3"
                      v-b-modal="`delete-modal-${item.id}`"
                    >
                      Delete
                    </button>
                    <b-modal
                      :id="`delete-modal-${item.id}`"
                      :ref="`delete-modal-${item.id}`"
                      hide-footer
                      hide-header
                    >
                      <p class="my-4">
                        Are you sure you want to delete message?
                      </p>
                      <b-button
                        class="mt-3"
                        @click="
                          deleteMessage(item.id);
                          $bvModal.hide(`delete-modal-${item.id}`);
                        "
                        >Delete</b-button
                      >
                    </b-modal>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <!--end::Table-->
      </div>
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  GET_MESSAGES_LIST,
  DELETE_MESSAGE
} from "@/core/services/store/message.module.js";

export default {
  name: "message-list",
  data: () => ({}),
  mounted() {
    this.refreshList();
  },
  computed: {
    ...mapState({
      list: state => state.message.messages
    })
  },
  methods: {
    refreshList() {
      this.id = this.$route.params.id;
      this.$store.dispatch(GET_MESSAGES_LIST, this.id);
    },
    deleteMessage(messageId) {
      this.$store.dispatch(DELETE_MESSAGE, messageId).then(data => {
        this.$store.dispatch("showSnackbar", {
          message: "Message deleted successfully",
          color: "success"
        });
      });
    }
  },
  watch: {
    $route(to, from) {
      this.refreshList();
    }
  }
};
</script>
